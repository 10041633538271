import { ReactNode } from "react";
import {
  ShowIcon,
  HeartOutlineIcon,
  CandidateStatusIcon,
} from "@welcome-ui/icons";

import Profile from "./assets/Profile.png";
import Binoculars from "./assets/Binoculars.png";
import Heart from "./assets/Heart.png";

import { palette } from "@otta/design-tokens";

export interface CardItem {
  id: string;
  title: string;
  subtitle: string;
  image: ReactNode;
  buttonText: string;
  backgroundColor: string;
  link: string;
}

type Wave = "wave1" | "wave2" | "control";

function OldImage({ src }: { src: string }): React.ReactElement {
  return <img style={{ width: 120, height: 120 }} src={src} />;
}

export function takeAnotherLook(wave: Wave): CardItem {
  return {
    id: "take-another-look",
    title: "Take another look",
    subtitle: "1 in 4 candidates save a job they skipped before.",
    image: wave === "wave2" ? <ShowIcon /> : <OldImage src={Binoculars} />,
    buttonText: "Take another look",
    backgroundColor: palette.brand.white,
    link: "/jobs/theme/take-another-look",
  };
}

export function reviewSavedJobs(wave: Wave): CardItem {
  return {
    id: "saved-jobs",
    title: "Review saved jobs",
    subtitle: "Apply for the jobs you've shown interest in.",
    image: wave === "wave2" ? <HeartOutlineIcon /> : <OldImage src={Heart} />,
    buttonText: "Review saved jobs",
    backgroundColor:
      wave === "wave2" ? palette.brand.white : palette.brand.blue,
    link: "",
  };
}

export function reviewProfile(wave: Wave): CardItem {
  return {
    id: "review-profile",
    title: "Review your profile",
    subtitle: "Review and update your profile to help you stand out.",
    image:
      wave === "wave2" ? <CandidateStatusIcon /> : <OldImage src={Profile} />,
    buttonText: "Review profile",
    backgroundColor:
      wave === "wave2" ? palette.brand.white : palette.brand.green,
    link: "/profile",
  };
}

export function createProfile(wave: Wave): CardItem {
  return {
    id: "create-profile",
    title: "Create a profile",
    subtitle: "Apply for jobs faster and get seen by companies hiring.",
    image:
      wave === "wave2" ? <CandidateStatusIcon /> : <OldImage src={Profile} />,
    buttonText: "Create profile",
    backgroundColor:
      wave === "wave2" ? palette.brand.white : palette.brand.green,
    link: "/profile",
  };
}
