import { Children as ReactChildren } from "react";
import styled, { x, css, down, up } from "@xstyled/styled-components";
import { motion } from "framer-motion";

import { ApplicationProcess } from "../components/ApplicationProcess";
import { CompanyBenefits } from "../components/CompanyBenefits";
import { JobInvolves } from "../components/JobInvolves";
import { JobRequirements } from "../components/JobRequirements";
import { VisaDisclaimer } from "../components/VisaDisclaimer";
import {
  CompanyOtherJobsButton,
  CompanyOtherJobsSection,
} from "../components/CompanyOtherJobs";
import { JobCardSection } from "../components/JobCardSection";
import SalaryBenchmarks from "../components/SalaryBenchmarks";
import { MeetTheTeamSection } from "../components/MeetTheTeamSection";
import { ShareLink } from "../components/ShareLink";
import { ReportAProblem } from "../components/ReportAProblem";
import { HideCompanyLink } from "../components/HideCompanyLink";
import { JobCardContext } from "../JobHeader";

import { useTrackEBContent } from "./JobContent";

import { CompanyFunding } from "@otta/search/components/CompanyFunding";
import { OttasTake } from "@otta/search/components/OttasTake";
import {
  Insights,
  shouldDisplayInsights,
} from "@otta/search/components/Insights";
import { palette } from "@otta/design-tokens";
import { PeopleBreakdown } from "@otta/search/components/PeopleAtCompany/PeopleBreakdown";
import { useHasEBContent } from "@otta/search/hooks/useHasEBContent";

const ColumnContainer = styled.div`
  display: flex;
  gap: xl;
  max-width: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 50%;
  flex-direction: column;
  gap: xl;
  margin-bottom: xl;
`;

const JobContentContainer = styled.div`
  background-color: beige-200;
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 67rem;
  width: 100%;

  ${down(
    "tablet",
    css`
      padding-bottom: xl;
    `
  )}

  ${up(
    "tablet",
    css`
      padding: 0 xl 40 xl;
    `
  )}
`;

const ActionButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: lg;
`;

const SeparatorWrapper = styled.div`
  > div {
    // First non-empty child after an empty div
    &:empty + div:not(:empty) {
      border-top: none;
      padding-top: 0;
      margin-top: 0;
    }

    // All non-empty children after the first that don't have an element with the hidden attribute
    // and handle non-empty divs even if there are empty siblings in between
    &:not(:empty):not(:first-child):not(:has([hidden])),
    &:not(:empty) ~ div:not(:empty),  // Account for non-empty divs after other non-empty divs
    &:not(:empty) ~ div:empty + div:not(:empty) {
      // Handle cases where an empty div is between non-empty ones
      border-top: 1px solid ${palette.grayscale.shade200};
      padding-top: lg;
      margin-top: lg;
    }

    // Ensure the last non-empty child also gets the separator
    &:not(:empty):last-child {
      border-top: 1px solid ${palette.grayscale.shade200};
      padding-top: lg;
      margin-top: lg;
    }
  }
`;

interface SeparateContentProps {
  children: React.ReactNode;
}

export function SeparateContent({
  children,
}: SeparateContentProps): React.ReactElement {
  return (
    <SeparatorWrapper>
      {ReactChildren.map(children, child => (
        <div>{child}</div>
      ))}
    </SeparatorWrapper>
  );
}

/* 
  TODO:
  For the yellow header height animations, make the ContentContainer have relative positioning
  and the yellow bit and card bits have absolute positioning. Then create a ref to the top section
  and use that to query its height and pass that into the styled component for the yellow bit every time
  te job data changes (useEffect). Use framer motion to animate the height change.
  This way, the card content can fly off to the side and the yellow background can animate independently.
*/

export function DesktopContent({
  job,
  user,
  otherJobsExpanded,
  expandOtherJobs,
  toggleKeyboardShortcuts,
}: JobCardContext): React.ReactElement {
  return (
    <JobContentContainer data-testid="job-card-main">
      <ColumnContainer>
        <LeftContent
          job={job}
          user={user}
          otherJobsExpanded={otherJobsExpanded}
          expandOtherJobs={expandOtherJobs}
          toggleKeyboardShortcuts={toggleKeyboardShortcuts}
        />
        <RightContent job={job} user={user} />
      </ColumnContainer>
    </JobContentContainer>
  );
}

function LeftContent({
  job,
  user,
  otherJobsExpanded,
  expandOtherJobs,
  toggleKeyboardShortcuts,
}: Pick<
  JobCardContext,
  | "job"
  | "user"
  | "expandOtherJobs"
  | "otherJobsExpanded"
  | "toggleKeyboardShortcuts"
>): React.ReactElement {
  return (
    <Column>
      <JobCardSection title="Role">
        <SeparateContent>
          <JobRequirements job={job} />
          <JobInvolves job={job} />
          <ApplicationProcess job={job} />
          <SalaryBenchmarks job={job} user={user} />
        </SeparateContent>
      </JobCardSection>
      <JobCardSection>
        <ActionButtonContainer>
          <ShareLink job={job} />
          <ReportAProblem
            job={job}
            user={user}
            toggleKeyboardShortcuts={toggleKeyboardShortcuts}
          />
          <HideCompanyLink company={job.company} user={user} />
          <CompanyOtherJobsButton
            company={job.company}
            user={user}
            currentJobId={job.id}
            expanded={otherJobsExpanded}
            expand={expandOtherJobs}
          />
        </ActionButtonContainer>
      </JobCardSection>
      {otherJobsExpanded && job.company.liveJobs.length > 1 && (
        <motion.div
          key="job-card-company-jobs"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ opacity: { ease: "easeInOut" } }}
        >
          <JobCardSection>
            <CompanyOtherJobsSection
              company={job.company}
              user={user}
              currentJobId={job.id}
            />
          </JobCardSection>
        </motion.div>
      )}
    </Column>
  );
}

function RightContent({
  job,
  user,
}: Pick<JobCardContext, "job" | "user">): React.ReactElement {
  const hasEBContent = useHasEBContent();

  const { meetTheTeamSectionRef, peopleBreakdownRef } = useTrackEBContent(job);

  return (
    <Column data-testid="right-column">
      {shouldDisplayInsights(job.company) && (
        <JobCardSection title="Insights">
          <Insights job={job} company={job.company} />
        </JobCardSection>
      )}
      {!!job.team && (
        <x.div mt={job.team ? 0 : "-xl"} ref={meetTheTeamSectionRef}>
          {hasEBContent && (
            <MeetTheTeamSection
              team={job.team}
              members={job.teamMembers}
              jobTitle={job.title}
              jobId={job.externalId}
            />
          )}
        </x.div>
      )}
      <JobCardSection title="Company">
        <SeparateContent>
          <CompanyBenefits job={job} company={job.company} />
          <CompanyFunding company={job.company} />
          {!!job.company.ebStatistics?.peopleBreakdown && (
            <div ref={peopleBreakdownRef}>
              {hasEBContent && <PeopleBreakdown company={job.company} />}
            </div>
          )}
          <VisaDisclaimer job={job} company={job.company} user={user} />
          <OttasTake company={job.company} hasTitle />
        </SeparateContent>
      </JobCardSection>
    </Column>
  );
}
