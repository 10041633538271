import styled from "@xstyled/styled-components";

import { pxToRem, palette } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import {
  Tag,
  TagsContainer as DefaultTagsContainer,
} from "@otta/search/components/Tag";
import {
  Job,
  TechnologyPreference,
  UserJobPreferences,
} from "@otta/search/schema";

const TagsContainer = styled(DefaultTagsContainer)`
  gap: sm;

  > div {
    border-radius: 4;
    margin: 0;
    padding: xs sm;
  }
`;

const Wrapper = styled.div`
  display: flex;
  gap: sm;
  font-size: 1rem !important;

  > svg {
    min-height: ${pxToRem(24)};
    min-width: ${pxToRem(24)};
  }
`;

const preferenceColor = (preference: TechnologyPreference | undefined) => {
  switch (preference) {
    case TechnologyPreference.VeryPositive:
      return palette.brand.green;
    case TechnologyPreference.Positive:
      return palette.brand.green;
    default:
      return palette.beige.shade200;
  }
};

const isPreferred = (preference: TechnologyPreference | undefined) => {
  return (
    preference === TechnologyPreference.VeryPositive ||
    preference === TechnologyPreference.Positive
  );
};

interface TechnologiesUsedTagsProps {
  job: Pick<Job.Fragment, "technologiesUsed">;
  user?: Pick<UserJobPreferences.CurrentUser, "technologiesUsedPreferences">;
}

export function TechnologiesUsedTags({
  job: { technologiesUsed },
  user,
}: TechnologiesUsedTagsProps): React.ReactElement | null {
  if (technologiesUsed.length === 0) {
    return null;
  }

  const userPreferences = user?.technologiesUsedPreferences ?? [];

  const preferencesMap = userPreferences.reduce<
    Map<string, TechnologyPreference>
  >(
    (map, pref) =>
      map.set(
        pref.technologyUsedId,
        pref.technologyPreference as TechnologyPreference
      ),
    new Map()
  );

  const sortedTechnologies = technologiesUsed.slice().sort((a, b) => {
    const prefA = preferencesMap.get(a.id) as TechnologyPreference;
    const prefB = preferencesMap.get(b.id) as TechnologyPreference;

    return prefA === undefined
      ? 1
      : prefB === undefined
      ? -1
      : prefA === TechnologyPreference.VeryPositive &&
        prefB === TechnologyPreference.Positive
      ? -1
      : 1;
  });

  return (
    <Wrapper>
      <Icon icon="technology" size={2} />
      <TagsContainer alignLeft data-testid="job-technology-used">
        {sortedTechnologies.map(({ id, value }) => (
          <Tag
            key={id}
            disabled
            bold={isPreferred(preferencesMap.get(id))}
            size={0.2}
            backgroundColor={preferenceColor(preferencesMap.get(id))}
          >
            {value}
          </Tag>
        ))}
      </TagsContainer>
    </Wrapper>
  );
}
