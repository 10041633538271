import { Experiment } from "../constants/experiments";

import { useExperiment } from "./unleash";

export function useWelcomeToTheJungle(): string {
  return useWave() === "wave2" ? "Welcome to the Jungle" : "Otta";
}

export function useWave(): "wave1" | "wave2" | "control" {
  const variant = useExperiment(Experiment.OttaWttjRebranding)?.variant;

  return import.meta.env.VITE_BRAND === "welcome"
    ? "wave2"
    : ((variant ?? "control") as "wave1" | "wave2" | "control");
}
