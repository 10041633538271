export interface BaseJobCardNavProps {
  showKeyboardHints: boolean;
  showApplyButton: boolean;
  onApply: () => void;
  onSave: () => void;
  onNext: () => void;
}

export interface JobDeckNavProps extends BaseJobCardNavProps {
  onPrevious: () => void;
  showPrevArrow: boolean;
  isSaved: boolean;
  isApplied: boolean;
}

export type JobCardNavProps = BaseJobCardNavProps | JobDeckNavProps;

export enum ArrowDirection {
  Right = "right",
  Left = "left",
}
