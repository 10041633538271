import styled, { css, down } from "@xstyled/styled-components";

import { Link } from "./icons/Link";
import ProfileIllustration from "./ProfileIllustration.png";

import { Button, Middle, Modal, Text, VerticalSpacing } from "@otta/design";
import { palette, pxToRem } from "@otta/design-tokens";
import { Icon, OttaIconsId } from "@otta/icons";
import { useHasNewLogo } from "@otta/search/components/Logo";

const ModalContent = styled.div`
  padding: xl;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-bottom: sm;
  width: 100%;
`;

const CenteredRowWrapper = styled(RowWrapper)`
  align-items: center;
`;

const ButtonWrapper = styled(RowWrapper)`
  flex-direction: row;

  ${down(
    "tablet",
    css`
      flex-direction: column;
    `
  )}
`;

const IconWrapper = styled.div`
  padding-right: sm;
`;

const StyledIcon = styled(Icon)`
  margin-right: sm;
`;

const StyledMiddle = styled(Middle)`
  background-color: yellow-100;
  border-radius: 8;
  padding: md lg;
  justify-content: center;
  text-align: left;
  padding-bottom: 0;
`;

const CancelButton = styled(Button)`
  width: 50%;
  margin-top: 0;
  margin-left: 6;
  height: ${pxToRem(40)};

  ${down(
    "tablet",
    css`
      width: 100%;
      margin-top: 6;
      margin-left: 0;
    `
  )}
`;

const SendButton = styled(CancelButton)`
  color: red;
  border-color: red;
  background: ${palette.grayscale.shade50};
  margin: 0;
  margin-right: 6;

  :hover {
    color: red-600;
    border-color: red-600;
    background: ${palette.brand.grey};
  }

  ${down(
    "tablet",
    css`
      margin: 0;
      margin-bottom: 6;
    `
  )}
`;

const Image = styled.img`
  width: ${pxToRem(215)};

  ${down(
    "tablet",
    css`
      display: none;
    `
  )}
`;

interface BulletPointProps {
  icon: OttaIconsId;
  text: string;
}

const BulletPoint = ({ icon, text }: BulletPointProps): React.ReactElement => {
  return (
    <RowWrapper>
      <StyledIcon icon={icon} />
      <Text>{text}</Text>
    </RowWrapper>
  );
};

interface IncompleteProfileModalProps {
  onSubmit: () => void;
  onCancel: () => void;
  candidateFirstName: string;
  hasTechJobFunctionPrefs: boolean;
}

export const IncompleteProfileModal = ({
  onSubmit,
  onCancel,
  candidateFirstName,
  hasTechJobFunctionPrefs,
}: IncompleteProfileModalProps): React.ReactElement => {
  const hasNewLogo = useHasNewLogo();

  return (
    <Modal
      open
      dismissable={false}
      onOpenChange={o => {
        if (!o) {
          onCancel();
        }
      }}
    >
      <ModalContent>
        <VerticalSpacing>
          <RowWrapper>
            <StyledIcon icon="article" size={2} />
            <Text data-cs-mask align="left" bold>
              {candidateFirstName}, you&apos;re more likely to get an interview
              if your application includes:
            </Text>
          </RowWrapper>
          <StyledMiddle>
            <CenteredRowWrapper>
              <div>
                <BulletPoint
                  icon="industry"
                  text="What you worked on in your last three roles"
                />
                {hasTechJobFunctionPrefs && (
                  <BulletPoint
                    icon="technology"
                    text="Any technologies you used"
                  />
                )}
                <BulletPoint icon="education" text="Your education" />
                <RowWrapper>
                  <IconWrapper>
                    <Link />
                  </IconWrapper>
                  <Text>A link to your LinkedIn profile</Text>
                </RowWrapper>
              </div>
              {!hasNewLogo && <Image src={ProfileIllustration} />}
            </CenteredRowWrapper>
          </StyledMiddle>
          <Text>
            You can stand out by also including snippets, more about you and
            interests.
          </Text>
          <Text>We&apos;ll save any edits to your profile automatically.</Text>
          <ButtonWrapper>
            <SendButton
              data-testid="confirm-send-application"
              level="primary"
              onClick={onSubmit}
            >
              Send now
            </SendButton>
            <CancelButton level="primary" onClick={onCancel}>
              Edit application
            </CancelButton>
          </ButtonWrapper>
        </VerticalSpacing>
      </ModalContent>
    </Modal>
  );
};
