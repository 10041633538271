import styled from "@xstyled/styled-components";
import { useEffect } from "react";

import { Button, Middle, Spacing, Text } from "@otta/design";
import { Link } from "@otta/search/components/Link";
import { pushAnalyticsEvent } from "@otta/analytics";
import { useWelcomeToTheJungle } from "@otta/search/hooks/useWelcomeToTheJungle";

const Container = styled.div`
  overflow-y: scroll;
  display: flex;
  margin-top: 5xl;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    text-align: center;
  }
`;

export function Recruiter(): React.ReactElement {
  const companyName = useWelcomeToTheJungle();

  const companiesEmail =
    companyName === "Welcome to the Jungle"
      ? "companies@welcometothejungle.com"
      : "companies@otta.com";

  useEffect(() => {
    pushAnalyticsEvent({
      eventName: "User Viewed Account No Access Page",
      name: "recruiter-viewing-search-app",
    });
  }, []);

  return (
    <Container>
      <Middle maxWidth={480}>
        <Spacing size={2}>
          <Text as="h2" bold align="center" size={2}>
            You can&apos;t access this page
          </Text>
          <Text align="center">
            You are logged in to your company account. The page you are trying
            to access is for candidates.
          </Text>
          <Spacing>
            <Button
              as="a"
              level="primary"
              href={`https://hire.${
                companyName === "Welcome to the Jungle"
                  ? "welcometothejungle"
                  : "otta"
              }.com`}
              style={{ width: "100%" }}
            >
              Back to hiring
            </Button>
            <Button
              as="a"
              level="destructive"
              href="/logout"
              style={{ width: "100%" }}
            >
              Log out
            </Button>
          </Spacing>
          <Text align="center">
            If this doesn&apos;t look right,{" "}
            <Link
              style={{ textDecoration: "underline" }}
              to={`mailto:${companiesEmail}?subject=Convert company account to candidate account`}
            >
              email us at {companiesEmail}
            </Link>
            .
          </Text>
        </Spacing>
      </Middle>
    </Container>
  );
}
