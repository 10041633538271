import { Spacing, Text } from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import { useWelcomeToTheJungle } from "@otta/search/hooks/useWelcomeToTheJungle";
import {
  LanguageRequirementsDocument,
  LatestJobApplication,
} from "@otta/search/schema";

export function OttaInsights({
  candidate,
}: {
  candidate: LatestJobApplication.Candidate;
}): React.ReactElement | null {
  const { data: languageData } = useQuery(LanguageRequirementsDocument);
  const companyName = useWelcomeToTheJungle();

  const workExperiences = candidate.workExperiences;
  const workedAtOttaCompany =
    workExperiences?.filter(({ companyOnOtta }) => companyOnOtta).length > 0;

  const allLanguages = languageData?.languageRequirements ?? [];
  const languagesSpoken =
    (candidate.languageRequirementPreferences ?? []).length > 0
      ? candidate.languageRequirementPreferences.map(
          ({ languageRequirementId: id }) =>
            allLanguages.find(({ id: languageId }) => id === languageId)?.value
        )
      : null;

  if (!languageData || (!workedAtOttaCompany && !languagesSpoken)) {
    return null;
  }

  return (
    <div data-testid="otta-insights-section">
      <Spacing size={-4}>
        {workedAtOttaCompany && (
          <Text size={-1} align="left" data-testid="company-on-otta-insight">
            Previous work experience at a{" "}
            <Text as="span" bold>
              company on {companyName}
            </Text>
          </Text>
        )}
        {languagesSpoken && (
          <Text size={-1} align="left" data-testid="languages-spoken-insight">
            Speaks{" "}
            <Text data-cs-mask as="span" bold>
              {languagesSpoken.length + 1} languages
            </Text>
            : English, {languagesSpoken.join(", ")}
          </Text>
        )}
      </Spacing>
    </div>
  );
}
