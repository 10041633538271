import { Field, FieldRenderProps } from "react-final-form";

import { SelectQuestion } from "../types";

import { Labelled } from "./Label";

import { Spacing } from "@otta/design";
import { SelectAtsQuestionOption } from "@otta/search/schema";
import { Select } from "@otta/search/components/Input/Select";

type FieldProps = {
  question: SelectQuestion;
  sectionId: string;
};

/**
 * This is the type we give to the internal Select / MultiSelect element,
 * we then have to map it into one or more AtsQuestionAnswerValueInputs
 */
type SelectOption = {
  label: string;
  value: string;
};

type InputProps = FieldProps &
  FieldRenderProps<unknown, HTMLElement, SelectOption | undefined> & {
    labelId: string;
  };

/**
 * A simple select field that only lets you set one option and has no free text input,
 * all the more complex use cases are handled with SelectField which uses checkboxes / radios
 */
export function SingleSelectField({
  question,
  sectionId,
}: FieldProps): React.ReactElement {
  const labelId = `${sectionId}_${question.id}_label`;
  return (
    <Labelled value={question.label} required={question.required} id={labelId}>
      <Field<string | undefined, HTMLElement, SelectOption | undefined>
        name={`s_${sectionId}.q_${question.localId}.a_0.idValue`}
        format={as => toChosenOption(question.options, as)}
        parse={is => (is ? is.value : undefined)}
        id={`${sectionId}_${question.id}`}
        multiple={false}
        initialValue={
          question?.selectAnswers?.length
            ? question?.selectAnswers[0].idValue
            : undefined
        }
        render={props => (
          <Input
            sectionId={sectionId}
            question={question}
            labelId={labelId}
            {...props}
          />
        )}
      />
    </Labelled>
  );
}

/**
 * Render the actual select with the helper props provided by Final Form.
 */
function Input(props: InputProps): React.ReactElement {
  return (
    <Spacing>
      <Select<SelectOption>
        aria-labelledby={props.labelId}
        options={props.question.options.map(o => toSelectOption(o))}
        {...props.input}
      />
    </Spacing>
  );
}

function toChosenOption(
  options: SelectAtsQuestionOption[],
  answerId: string | undefined
): SelectOption | undefined {
  if (answerId) {
    const label = options.find(o => o.localId == answerId)?.label as string;
    return { label, value: answerId };
  }
}

function toSelectOption(opt: SelectAtsQuestionOption): SelectOption {
  return {
    value: opt.localId,
    label: opt.label,
  };
}
