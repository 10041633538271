import { Helmet } from "react-helmet-async";
import { Outlet } from "react-router-dom";

import { useWelcomeToTheJungle } from "@otta/search/hooks/useWelcomeToTheJungle";

export function CompaniesHead(): React.ReactElement {
  const companyName = useWelcomeToTheJungle();

  return (
    <>
      <Helmet>
        <title>
          Companies - {companyName} - The better way to find a job in tech
        </title>
      </Helmet>
      <Outlet />
    </>
  );
}
