import { REMOTE_PREFERENCES } from "@otta/search/globalConstants";
import { Location, LocationPreference, PublicJob } from "@otta/search/schema";

const bullets = (fieldName: string, items: { value: string | null }[]) => {
  if (items.length === 0) {
    return {};
  }
  return {
    [fieldName]: `<ul>\n${items.map(
      ({ value }) => `<li>${value}</li>`
    )}\n</ul>`,
  };
};

const description = (
  responsibilities: { value: string }[],
  requirements: { value: string; desirable: boolean }[]
) => ({
  description: `<h1>Requirements</h1>\n<ul>\n${requirements.map(
    ({ value, desirable }) =>
      `<li>${desirable ? "(Desirable) " : ""}${value}</li>`
  )}\n</ul>\n<h1>What the job involves</h1>\n<ul>\n${responsibilities.map(
    ({ value }) => `<li>${value}</li>`
  )}\n</ul>`,
});

type ApplicantLocationRequirement = { "@type": string; value: string };

const applicantLocationRequirements = (
  locationPreferences: LocationPreference[],
  acc: ApplicantLocationRequirement[] = []
): ApplicantLocationRequirement[] => {
  if (locationPreferences.length === 0) {
    return acc;
  }

  const [first, ...rest] = locationPreferences;

  switch (first.location) {
    case Location.RemoteUk:
      acc.push({ "@type": "Country", value: "GB" });
      break;
    case Location.RemoteUs:
      acc.push({ "@type": "Country", value: "US" });
      break;
    case Location.RemoteCanada:
      acc.push({ "@type": "Country", value: "CA" });
      break;
    case Location.RemoteFrance:
      acc.push({ "@type": "Country", value: "FR" });
      break;
    case Location.RemoteGermany:
      acc.push({ "@type": "Country", value: "DE" });
      break;
    case Location.RemoteIreland:
      acc.push({ "@type": "Country", value: "IE" });
      break;
    case Location.RemoteNetherlands:
      acc.push({ "@type": "Country", value: "NL" });
      break;
    case Location.RemoteSpain:
      acc.push({ "@type": "Country", value: "ES" });
      break;
    case Location.RemoteEurope:
      acc.push({ "@type": "Country", value: "FR" });
      acc.push({ "@type": "Country", value: "DE" });
      acc.push({ "@type": "Country", value: "IE" });
      acc.push({ "@type": "Country", value: "NL" });
      acc.push({ "@type": "Country", value: "ES" });
      break;
    default:
      break;
  }

  return applicantLocationRequirements(rest, acc);
};

export function jobPostingSchema({
  job,
}: {
  job: PublicJob.PublicJob;
}): string {
  const isRemote = job.locationPreferences.some(({ location }) =>
    REMOTE_PREFERENCES.includes(location)
  );
  const telecommuteText = isRemote
    ? {
        jobLocationType: "TELECOMMUTE",
        applicantLocationRequirements: applicantLocationRequirements(
          job.locationPreferences
        ),
      }
    : {};
  const data = {
    "@context": "https://schema.org/",
    "@type": "JobPosting",
    title: `${job.title} ${job.subtitle ? `(${job.subtitle})` : ""}`,
    identifier: {
      "@type": "URL",
      value: `${job.originalUrl}`,
    },
    datePosted: `${job.insertedAt}`,
    validThrough: `${job.validUntil}`,
    employmentType: "FULL_TIME",
    hiringOrganization: {
      "@type": "Organization",
      name: `${job.company.name}`,
      sameAs: `${job.company.websiteUrl}`,
      logo: `${job.company.logoPath}`,
    },
    ...telecommuteText,
    jobLocation: {
      "@type": "Place",
      address: {
        "@type": "PostalAddress",
        addressRegion: "London",
        addressCountry: "UK",
        addressLocality: "London",
      },
    },
    baseSalary: {
      "@type": "MonetaryAmount",
      currency: "GBP",
      minPrice: job.salaryRange?.minAmount,
      maxPrice: job.salaryRange?.maxAmount,
    },
    ...bullets("jobBenefits", job.company.otherBenefits),
    ...bullets("responsibilities", job.involvesBullets),
    ...description(job.involvesBullets, job.requirements),
    ...bullets("skills", job.requirements),
  };

  return JSON.stringify(data);
}
