import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import styled from "@xstyled/styled-components";
import { Outlet, useParams } from "react-router-dom";

import { useCompany } from "./useCompany";
import { CompanyHero } from "./components/CompanyHero";

import { Text, Middle, Spacing } from "@otta/design";
import { Spinner } from "@otta/search/components/Loading";
import { pushAnalyticsEvent, pushEventToDataLayer } from "@otta/analytics";
import { PageWrapper } from "@otta/search/components/PageWrapper";
import { NotFound } from "@otta/search/router";
import { useWelcomeToTheJungle } from "@otta/search/hooks/useWelcomeToTheJungle";

const SpinnerWrapper = styled.div`
  padding: 5xl 0;
`;

const ErrorText = styled(Text)`
  margin: 5xl 0;
`;

function Content({ urlSafeName }: { urlSafeName: string }): React.ReactElement {
  const { data: companyData, loading } = useCompany(urlSafeName);
  const company = companyData?.company;

  const companyName = useWelcomeToTheJungle();
  const isWelcome = companyName === "Welcome to the Jungle";

  useEffect(() => {
    if (company) {
      pushEventToDataLayer({
        event: "user-viewed-company",
        companyId: company.id,
        integratedAts: company.hasAtsIntegration,
        companySize: company.size?.value,
        companySector: company.sectorTags?.map(s => s.value),
      });
    }
  }, [company]);

  if (loading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  if (!company || company?.workflowStatus === "ARCHIVED") {
    return (
      <NotFound>
        <Middle>
          <ErrorText>This company does not exist or cannot be found</ErrorText>
        </Middle>
      </NotFound>
    );
  }

  return (
    <>
      <Helmet>
        {isWelcome ? (
          <title>
            {company.name} Jobs and Careers | Welcome to the Jungle (formerly
            Otta)
          </title>
        ) : (
          <title>
            Jobs at {company.name} - {companyName} - The only job search that
            does you justice
          </title>
        )}
        <meta
          name="description"
          content={
            isWelcome
              ? `Apply for jobs at ${
                  company.name
                } on Welcome to the Jungle (formerly Otta}. ${
                  company.mission ?? ""
                }`
              : `${company.name} is hiring. See employee endorsements, gender diversity, salaries, benefits and more.`
          }
        />
        <link
          rel="canonical"
          href={`https://app.${
            isWelcome ? "welcometothejungle" : "otta"
          }.com/companies/${encodeURIComponent(urlSafeName)}`}
        />
      </Helmet>
      <Spacing>
        <CompanyHero company={company} />
        <Outlet />
      </Spacing>
    </>
  );
}

export function Company(): React.ReactElement | null {
  const { companyUrlSafeName } = useParams();

  useEffect(() => {
    pushAnalyticsEvent({
      eventName: "Viewed Company Page",
      companyUrlSafeName,
    });
  }, [companyUrlSafeName]);

  return (
    <PageWrapper>
      <Content urlSafeName={companyUrlSafeName as string} />
    </PageWrapper>
  );
}
