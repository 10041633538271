import { useMutation } from "@apollo/client";

import { SectionHeader } from "../../components/FormHeader";
import { FormQueryCard } from "../../components/FormQueryCard";

import { DisplayAdditionalInfo, EditingAdditionalInfo } from "./AdditionalInfo";
import { DisplayDemographic, EditingDemographic } from "./Demographic";

import { Spacing } from "@otta/design";
import { handleMutationError } from "@otta/search/utils/error";
import {
  UserAdditionalInfoDocument,
  UserDemographicInfoDocument,
  UpdateCurrentUserDocument,
  UserDemographicInfoQuery,
  UserAdditionalInfoQuery,
  UserInput,
  ProfileCompletionStatusDocument,
} from "@otta/search/schema";
import { useWelcomeToTheJungle } from "@otta/search/hooks/useWelcomeToTheJungle";

export function PersonalDetails(): React.ReactElement {
  const [mutation] = useMutation(UpdateCurrentUserDocument, {
    onError: handleMutationError,
    refetchQueries: [{ query: ProfileCompletionStatusDocument }],
  });
  const companyName = useWelcomeToTheJungle();

  return (
    <Spacing>
      <SectionHeader>Personal details</SectionHeader>
      <FormQueryCard
        title="Demographic information"
        tip={`${companyName} is dedicated to building a recruitment platform to help companies hire in a more inclusive way. To accomplish this, we're asking for a bit more detail that's entirely optional`}
        query={UserDemographicInfoDocument}
        formatIncomingData={({ currentUser }: UserDemographicInfoQuery) => ({
          genders: currentUser?.genders.map(gender => ({
            value: gender,
          })),
          pronoun: currentUser?.pronoun,
          ethnicities: currentUser?.ethnicities.map(({ ethnicity }) => ({
            value: ethnicity,
          })),
        })}
        formatOutgoingData={({ ethnicities, genders, ...values }) => ({
          ...values,
          genders: (genders ?? []).map(op => op.value),
          ethnicities: (ethnicities ?? []).map(({ value }) => ({
            ethnicity: value,
          })),
        })}
        onSubmit={(input: UserInput) => mutation({ variables: { input } })}
        editingComponent={EditingDemographic}
        displayComponent={DisplayDemographic}
      />
      <FormQueryCard
        data-testid="additional-info-card"
        title="Additional information"
        query={UserAdditionalInfoDocument}
        formatIncomingData={({ currentUser }: UserAdditionalInfoQuery) => ({
          phoneNumber: currentUser?.phoneNumber,
          visaLocations: (currentUser?.visaRequirementCountries ?? []).map(
            ({ location: id }) => ({ value: id })
          ),
          languageRequirements: (
            currentUser?.languageRequirementPreferences ?? []
          ).map(({ languageRequirementId: id }) => ({ value: id })),
        })}
        formatOutgoingData={({
          phoneNumber,
          languageRequirements,
          visaLocations,
          ...rest
        }) => ({
          ...rest,
          phoneNumber: phoneNumber ?? null,
          visaRequirementCountries: (visaLocations ?? []).map(({ value }) => ({
            location: value,
          })),
          languageRequirementPreferences: (languageRequirements ?? []).map(
            ({ value }) => ({ languageRequirementId: value })
          ),
        })}
        onSubmit={(input: UserInput) => mutation({ variables: { input } })}
        editingComponent={EditingAdditionalInfo}
        displayComponent={DisplayAdditionalInfo}
      />
    </Spacing>
  );
}
