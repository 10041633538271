import styled, { css, up } from "@xstyled/styled-components";
import { Link } from "react-router-dom";

import { MiniCompanyCard } from "./MiniCompanyCard";

import { Text, Button, Spacing } from "@otta/design";
import { modularScale, palette } from "@otta/design-tokens";
import { useQuery } from "@otta/search/apollo";
import {
  CurrentUserStatisticsDocument,
  PersonalisedCompanies,
} from "@otta/search/schema";
import { useWelcomeToTheJungle } from "@otta/search/hooks/useWelcomeToTheJungle";

function splitList<T>(columns: number, list: T[]) {
  return Array(columns)
    .fill(null)
    .map((_, n) => {
      return list.filter((item, index) => index % columns === n);
    });
}

const splitCompanies = (
  companies: PersonalisedCompanies.PersonalisedCompanies[],
  sectorTagPreferences: { id: string; preference: boolean | null }[]
) => {
  const splitPoint = companies.findIndex(
    ({ sectorTags }) =>
      !sectorTags.some(({ id }) =>
        sectorTagPreferences.some(pref => pref.id === id && pref.preference)
      )
  );
  return [
    companies.slice(0, splitPoint),
    splitPoint >= 0 ? companies.slice(splitPoint) : [],
  ];
};

const ListContainer = styled.div`
  display: grid;
  gap: lg;
  @media (min-width: 400px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const CtaContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid ${palette.grayscale.shade200};
  padding: md sm;

  ${up(
    "tablet",
    css`
      display: none;
    `
  )}
`;

export function CompaniesList({
  data,
  investorPreferences,
  companySizes,
  sectorTagPreferences,
}: {
  companySizes: string[];
  sectorTagPreferences: { id: string; preference: boolean | null }[];
  investorPreferences: { id: string; preference: boolean | null }[];
  data?: {
    personalisedCompanies?: PersonalisedCompanies.PersonalisedCompanies[];
  };
}): React.ReactElement {
  const companies = (data && data.personalisedCompanies) || [];
  const companyName = useWelcomeToTheJungle();

  const [goodCompanies, otherCompanies] = splitCompanies(
    companies,
    sectorTagPreferences
  );

  const goodColumns = splitList(2, goodCompanies);
  const otherColumns = splitList(2, otherCompanies);

  const { data: userData } = useQuery(CurrentUserStatisticsDocument, {
    errorPolicy: "all",
  });

  return (
    <Spacing>
      {!userData?.currentUser && (
        <CtaContent>
          <Text as="h4" bold size={1}>
            {userData?.publicStatistics?.numberLiveCompanies.toLocaleString(
              "en-GB"
            ) ?? "10,000"}{" "}
            companies hiring on {companyName}
          </Text>
          <Button
            level="primary"
            as={Link}
            to="/sign-up"
            style={{
              textDecoration: "none",
              justifyContent: "center",
              textAlign: "center",
              padding: `${modularScale(-2)} ${modularScale(1)}`,
              whiteSpace: "nowrap",
            }}
          >
            Sign up
          </Button>
        </CtaContent>
      )}
      {goodCompanies.length > 0 && (
        <Spacing>
          <Text bold size={1} as="h6">
            Companies matching your preferences
          </Text>
          <ListContainer>
            {goodColumns.map((column, index) => (
              <Column key={index}>
                <Spacing>
                  {column.map(company => (
                    <MiniCompanyCard key={company.id} {...company} />
                  ))}
                </Spacing>
              </Column>
            ))}
          </ListContainer>
        </Spacing>
      )}
      {otherCompanies.length > 0 && (
        <Spacing>
          <Text bold size={1} as="h6" align="center">
            {goodCompanies.length > 0
              ? "Other companies to explore"
              : investorPreferences.length > 0 || companySizes.length > 0
              ? "Companies matching your preferences"
              : "Discover new companies"}
          </Text>
          <ListContainer>
            {otherColumns.map((column, index) => (
              <Column key={index}>
                <Spacing>
                  {column.map(company => (
                    <MiniCompanyCard key={company.id} {...company} />
                  ))}
                </Spacing>
              </Column>
            ))}
          </ListContainer>
        </Spacing>
      )}
    </Spacing>
  );
}
