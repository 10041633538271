import styled from "@xstyled/styled-components";
import { useState } from "react";

import { Tick } from "../icons/Tick";

import { pxToRem } from "@otta/design-tokens";
import { BottomPopup } from "@otta/search/components/BottomPopup";
import { shareUrl } from "@otta/search/utils/clipboard";
import { Share } from "@otta/search/components/Icons/Share";
import { useWelcomeToTheJungle } from "@otta/search/hooks/useWelcomeToTheJungle";
const IconWrapper = styled.div`
  display: flex;
  width: ${pxToRem(14)};
  align-items: center;
  justify-content: center;
  margin-right: md;
`;

export const ShareOttaPopup = ({
  onClose,
}: {
  onClose: () => void;
}): React.ReactElement => {
  const [linkCopied, setLinkCopied] = useState(false);
  const companyName = useWelcomeToTheJungle();

  const handleShareSuccess = (didCopy: boolean) => {
    if (didCopy) {
      setLinkCopied(true);
    }
  };
  const heading = "Don't keep us all to yourself";
  const subHeading = `There are over 5000 companies hiring on ${companyName} right now. Tell your friends!`;
  const data = {
    url: "https://otta.com?utm_source=apply_screen_share",
    title: `I think you'd love ${companyName}`,
    text: `Hey! I think you'd love ${companyName}. You get personalised job matches, straight to your inbox. Click to sign up:`,
  };
  const handleClick = () => {
    shareUrl({
      data,
      onComplete: handleShareSuccess,
    });
  };

  return (
    <BottomPopup
      handleClickCTA={handleClick}
      onClose={onClose}
      heading={heading}
      subHeading={subHeading}
      analyticsId="popup-share-button"
    >
      <IconWrapper>{linkCopied ? <Tick /> : <Share />}</IconWrapper>
      {linkCopied ? "Link copied to clipboard!" : `Share ${companyName}`}
    </BottomPopup>
  );
};
