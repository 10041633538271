import queryString from "query-string";
import { useContext, useMemo, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useEventProperties } from "./EventProperties";
import { sections } from "./consts";

import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import { SidebarQuiz } from "@otta/search/containers/Quiz/SidebarQuiz";
import {
  NotificationFrequency,
  PopularTechnologiesUsedDocument,
  TechnologyPreference,
} from "@otta/search/schema";
import {
  UK_LOCATIONS,
  EU_LOCATIONS,
  US_LOCATIONS,
} from "@otta/search/constants/locations";
import { UserPreferencesContext } from "@otta/search/providers/UserPreferencesProvider/UserPreferencesContext";
import { useHasFollowCompany } from "@otta/search/hooks/useHasFollowCompany";

export default function JobPreferences(): React.ReactElement {
  const location = useLocation();
  const navigate = useNavigate();

  const hasFollowCompany = useHasFollowCompany();

  const { preferencesData, onlyRemoteOpportunities, loading } = useContext(
    UserPreferencesContext
  );

  const initialState = useRef(preferencesData);
  const props = useEventProperties(preferencesData);

  const {
    data: popularTechnologiesData,
    previousData: previousPopularTechnologiesData,
  } = useQuery(PopularTechnologiesUsedDocument, {
    skip: !preferencesData,
    variables: {
      subFunctions: (preferencesData?.jobSubFunctionPreferences ?? []).map(
        pref => pref.subFunctionId
      ),
    },
  });

  const showPositiveTechnologies = useMemo(() => {
    return (
      (
        popularTechnologiesData?.popularTechnologiesUsed ??
        previousPopularTechnologiesData?.popularTechnologiesUsed ??
        []
      ).length > 5 ||
      (preferencesData?.technologiesUsedPreferences ?? []).filter(p =>
        [
          TechnologyPreference.VeryPositive,
          TechnologyPreference.Positive,
        ].includes(p.technologyPreference)
      ).length > 0
    );
  }, [
    popularTechnologiesData,
    preferencesData?.technologiesUsedPreferences,
    previousPopularTechnologiesData,
  ]);

  const showNegativeTechnologies = useMemo(
    () =>
      (
        popularTechnologiesData?.popularTechnologiesUsed ??
        previousPopularTechnologiesData?.popularTechnologiesUsed ??
        []
      ).length > 5 ||
      (preferencesData?.technologiesUsedPreferences ?? []).filter(p =>
        [TechnologyPreference.Negative].includes(p.technologyPreference)
      ).length > 0,
    [
      popularTechnologiesData,
      preferencesData?.technologiesUsedPreferences,
      previousPopularTechnologiesData,
    ]
  );

  const hasHiddenCompanies = useMemo(
    () =>
      Boolean(
        !loading &&
          preferencesData &&
          preferencesData.hiddenCompanies &&
          preferencesData.hiddenCompanies.length > 0
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading]
  );

  const hasFollowedCompanies = useMemo(
    () =>
      Boolean(
        !loading &&
          preferencesData &&
          preferencesData.followedCompanies &&
          preferencesData.followedCompanies.length > 0
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading]
  );

  if (!preferencesData || loading) {
    return <Loading />;
  }

  const showEmailNotifications =
    initialState.current?.jobEmailNotificationsFrequency ===
    NotificationFrequency.Never;

  const locationPreferences = (preferencesData?.locationPreferences ?? []).map(
    l => l.location
  );

  const multipleLocations =
    [
      locationPreferences.find(location => UK_LOCATIONS.has(location)),
      locationPreferences.find(location => US_LOCATIONS.has(location)),
      locationPreferences.find(location => EU_LOCATIONS.has(location)),
    ].filter(location => location).length > 1;

  const quizSections = sections({
    onlyRemoteOpportunities,
    multipleLocations,
    showPositiveTechnologies,
    showNegativeTechnologies,
    showEmailNotifications,
    hasHiddenCompanies,
    hasFollowedCompanies,
    showFollowedCompanies: hasFollowCompany,
  });

  const handleFinish = () => {
    const queryParams = queryString.parse(location.search);
    if (queryParams && typeof queryParams.redirect === "string") {
      const redirect = queryParams.redirect;
      navigate(redirect);
    } else {
      navigate("/jobs", { replace: true });
    }
  };

  return (
    <SidebarQuiz
      finishText="Finish"
      analyticsName="change-preferences"
      onFinish={handleFinish}
      sections={quizSections}
      eventProperties={props}
    />
  );
}
