import styled from "@xstyled/styled-components";

import { Paragraph, Spacing, Text } from "@otta/design";
import { palette } from "@otta/design-tokens";
import { Company } from "@otta/search/schema";
import { CircularImg } from "@otta/search/components/Img";
import Steph from "@otta/search/Images/Headshots/Steph.png";
import Freddie from "@otta/search/Images/Headshots/Freddie.png";
import Kirsty from "@otta/search/Images/Headshots/Kirsty.png";
import Caroline from "@otta/search/Images/Headshots/Caroline.jpg";
import { TooltipWithIcon } from "@otta/search/components/Tooltip";
import { useWave } from "@otta/search/hooks/useWelcomeToTheJungle";

const AuthorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
`;

const AuthorContainer = styled.div`
  align-items: center;
  display: flex;
  gap: md;
`;

type Author = {
  name: string;
  title: string;
  image: string;
};

const stephPerson = {
  name: "Steph",
  title: "Company Specialist",
  image: Steph,
} as Author;

const freddiePerson = {
  name: "Freddie",
  title: "Company Specialist",
  image: Freddie,
} as Author;

const kirstyPerson = {
  name: "Kirsty",
  title: "Company Specialist",
  image: Kirsty,
} as Author;

const carolinePerson = {
  name: "Caroline Fischer",
  title: "UK Country Manager",
  image: Caroline,
} as Author;

export const shouldDisplayOttasTake = (company: OttasTakeProps["company"]) => {
  return company.marketBullets.length > 0;
};

const people = [stephPerson, freddiePerson, kirstyPerson];
interface OttasTakeProps {
  company: Pick<Company, "marketBullets" | "name" | "id">;
  hasTitle?: boolean;
}

export function OttasTake({ company, hasTitle }: OttasTakeProps) {
  const variant = useWave();
  if (!shouldDisplayOttasTake(company)) {
    return null;
  }

  const { id, marketBullets } = company;

  const index = Number(id) % people.length;
  const person =
    id === "330" ? carolinePerson : people[isNaN(index) ? 2 : index];

  const authorTitle = (person: Author) => {
    return variant === "wave2" && person !== carolinePerson
      ? `${person.title} at Welcome to the Jungle`
      : person.title;
  };

  return (
    <Spacing>
      {hasTitle && (
        <TooltipWithIcon
          content="This is our opinion, written to help you understand more about the company. It's not intended as a statement of fact, but was written to be as accurate as possible at the time of publishing"
          iconColor={palette.grayscale.shade600}
        >
          <Text bold as="h2" size={1} data-testid="ottas-take">
            Our take
          </Text>
        </TooltipWithIcon>
      )}
      <Spacing>
        {marketBullets.map(({ id, value }) => (
          <Paragraph data-testid="company-market-bullet" key={id}>
            {value}
          </Paragraph>
        ))}
      </Spacing>
      <AuthorContainer>
        <CircularImg
          src={person.image}
          alt={`${person.name} headshot`}
          width="56px"
        />
        <AuthorWrapper>
          <Text bold>{person.name}</Text>
          <Text>{authorTitle(person)}</Text>
        </AuthorWrapper>
      </AuthorContainer>
    </Spacing>
  );
}
