import { useEffect, useMemo, useState } from "react";
import { Field, useForm } from "react-final-form";
import styled from "@xstyled/styled-components";

import { SelectQuestion } from "../types";

import { Labelled } from "./Label";

import { IInputFieldProps, InputField } from "@otta/design";
import { SelectAtsQuestionOption } from "@otta/search/schema";

type FieldProps = {
  question: SelectQuestion;
  sectionId: string;
};

type OptionProps = FieldProps & {
  option: SelectAtsQuestionOption;
  index: number;
};

export const OptionLabel = styled.label`
  margin: md 0;
  column-gap: 6;
  display: flex;
`;

/**
 * A select field that contains one or more freeform option - where
 * choosing it pops up a textbox allowing you to specify a new option.
 * Because there could be multiple freeform options we use checkboxes / radios.
 */
export function SelectField(props: FieldProps): React.ReactElement {
  return (
    <Labelled value={props.question.label} required={props.question.required}>
      {props.question.options.map((option, index) => (
        <div key={option.id}>
          {props.question.options.some(p => p.allowMultiple) ? (
            <Checkbox option={option} index={index} {...props} />
          ) : (
            <Radio option={option} index={index} {...props} />
          )}
          <FreeTextField option={option} index={index} {...props} />
        </div>
      ))}
    </Labelled>
  );
}

/**
 * We do some weird stuff here because arrays of objects don't work well in final form
 * so for checkboxes we instead create an object where the keys are a_{index}.
 * Radio buttons all need the same name to be mutually exclusive.
 */
function inputName(props: OptionProps, suffix: string): string {
  return props.question.options.some(p => p.allowMultiple)
    ? `s_${props.sectionId}.q_${props.question.localId}.a_${props.index}.${suffix}`
    : `s_${props.sectionId}.q_${props.question.localId}.a_0.${suffix}`;
}

/**
 * Create a checkbox field, this has to be separate to radio
 * because if you set a value on this it breaks final-form
 */
function Checkbox(props: OptionProps): React.ReactElement {
  const buildOnChange = useOnChangeHandler(props);

  return (
    <OptionLabel>
      <Field<string | undefined, HTMLElement, boolean | undefined>
        type={"checkbox"}
        format={i => !!i}
        name={inputName(props, "idValue")}
        parse={i => (i ? props.option.localId : undefined)}
        initialValue={
          props.question.selectAnswers?.find(
            a => a.idValue === props.option.localId
          )?.idValue ?? undefined
        }
        render={({ input: { onChange, value, ...input } }) => {
          return (
            <input
              onChange={buildOnChange(onChange)}
              value={`${value}`}
              {...input}
            />
          );
        }}
      />
      {props.option.label}
    </OptionLabel>
  );
}

/**
 * Intercept the checkbox onChange handler
 * so if you tick allowMultiple = false we unset everything,
 * or we unset anything with allowMultiple = false if you tick something else.
 * We can't directly call form.change in the handler so I use a weird state thin
 */
function useOnChangeHandler(
  props: OptionProps
): (
  c: React.ChangeEventHandler<HTMLInputElement>
) => React.ChangeEventHandler<HTMLInputElement> {
  const [queue, enqueue] = useState<string[]>([]);
  const { change } = useForm();

  /*
   * If we've queued some IDs to clear then call form.change
   * and clear off the queue
   */
  useEffect(() => {
    if (queue.length > 0) {
      queue.forEach(q => change(q, undefined));
      enqueue([]);
    }
  }, [queue, change]);

  const toUnset: string[] = useMemo(
    () =>
      props.question.options.flatMap((option, index) => {
        const optionProps: OptionProps = { ...props, option, index };
        const fieldsToClear: string[] = [];

        if (
          option.id !== props.option.id &&
          (!option.allowMultiple || !props.option.allowMultiple)
        ) {
          fieldsToClear.push(inputName(optionProps, "idValue"));
        }

        return fieldsToClear;
      }),
    [props]
  );

  return next => event => {
    enqueue(toUnset);
    return next(event);
  };
}

/**
 * By contrast you absolutely must set a value on radio elements
 * The docs don't bother to mention any of this, naturally
 */
function Radio(props: OptionProps): React.ReactElement {
  return (
    <OptionLabel>
      <Field<string | undefined, HTMLElement, string | undefined>
        name={inputName(props, "idValue")}
        value={props.option.localId}
        component="input"
        type={"radio"}
        initialValue={
          props.question.selectAnswers?.find(Boolean)?.idValue ?? undefined
        }
      />
      {props.option.label}
    </OptionLabel>
  );
}

/**
 * Render a free text field that will only pop up if a freeform option is chosen,
 * otherwise it'll render a <FieldCleaner> component to explicitly unset any free text
 */
function FreeTextField(props: OptionProps): React.ReactElement | null {
  return !props.option.freeText ? null : (
    <Field name={inputName(props, "idValue")} subscription={{ value: true }}>
      {({ input: { value: parentValue } }) =>
        parentValue == props.option.localId && (
          <Field
            initialValue={
              props.question.selectAnswers?.find(
                answer => answer.idValue === props.option.localId
              )?.stringValue ?? undefined
            }
            name={inputName(props, "stringValue")}
            parse={v => (parentValue == props.option.localId ? v : undefined)}
            render={({ input, meta: { touched, error } }) => (
              <SelfCleaningInputField
                {...input}
                type="text"
                error={touched && error}
                data-testid={`${props.option.id}-free-text`}
              />
            )}
          />
        )
      }
    </Field>
  );
}

/**
 * An input field that clears its value when it is unmounted.
 * Eight hours of my life I won't get back...
 */
function SelfCleaningInputField(props: IInputFieldProps): React.ReactElement {
  const { change } = useForm();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => change(props.name, undefined), []);
  return <InputField {...props} />;
}
