import styled, { css, down } from "@xstyled/styled-components";
import { useMutation } from "@apollo/client";
import { useCallback, useMemo, useState } from "react";

import { pxToRem } from "@otta/design-tokens";
import {
  Company,
  FollowCompanyDocument,
  Job,
  UnfollowCompanyDocument,
  UserJobPreferences,
} from "@otta/search/schema";
import { Button } from "@otta/design";
import { Icon } from "@otta/icons";
import { useHasFollowCompany } from "@otta/search/hooks/useHasFollowCompany";
import { FollowedCompanyModal } from "@otta/search/components/FollowedCompanyModal";

interface FollowCompanyProps {
  company: Pick<Company.Fragment, "id" | "name">;
  job?: Pick<Job.Fragment, "id">;
  user?:
    | Pick<UserJobPreferences.CurrentUser, "followedCompanies">
    | undefined
    | null;
}

const FollowCompanyButton = styled(Button)`
  min-height: ${pxToRem(44)};
  font-size: ${pxToRem(16)};
  padding: sm xl sm xl;
  gap: 10;
  margin-top: md;

  ${down(
    "tablet",
    css`
      width: 100%;
    `
  )}
`;

const FollowCompanyIcon = styled(Icon)`
  font-size: ${pxToRem(24)};
`;

export function FollowCompany({
  company: { id: companyId, name },
  job,
  user,
}: FollowCompanyProps) {
  const [followCompany, { loading: loadingFollow }] = useMutation(
    FollowCompanyDocument
  );

  const [unfollowCompany, { loading: loadingUnfollow }] = useMutation(
    UnfollowCompanyDocument
  );

  const [showFollowedCompanyModal, setShowFollowedCompanyModal] =
    useState(false);

  const handleFollowCompany = useCallback(() => {
    if (user?.followedCompanies && user.followedCompanies.length === 0) {
      setShowFollowedCompanyModal(true);
    }
    followCompany({ variables: { companyId, jobId: job?.id } });
  }, [followCompany, companyId, job?.id, user?.followedCompanies]);

  const handleUnFollowCompany = useCallback(() => {
    unfollowCompany({ variables: { companyId, jobId: job?.id } });
  }, [unfollowCompany, companyId, job?.id]);

  const hasFollowCompany = useHasFollowCompany();

  const isCompanyFollowed = useMemo(
    () =>
      user?.followedCompanies?.some(
        followedCompany => followedCompany?.company?.id === companyId
      ) ?? false,
    [companyId, user?.followedCompanies]
  );

  const isCompanyPage = useMemo(() => !job, [job]);

  if (!hasFollowCompany || !user) {
    return null;
  }

  return (
    <div>
      <FollowCompanyButton
        onClick={
          isCompanyFollowed ? handleUnFollowCompany : handleFollowCompany
        }
        disabled={isCompanyFollowed ? loadingUnfollow : loadingFollow}
        level={isCompanyPage ? "primary" : "secondary"}
      >
        <FollowCompanyIcon
          icon={isCompanyFollowed ? "notification-on" : "notification-off"}
        />
        {isCompanyFollowed ? "Following" : "Follow"} {isCompanyPage ? "" : name}
      </FollowCompanyButton>
      {showFollowedCompanyModal && (
        <FollowedCompanyModal
          handleClose={() => setShowFollowedCompanyModal(false)}
          open={showFollowedCompanyModal}
          companyName={name}
        />
      )}
    </div>
  );
}
