import { Field } from "react-final-form";
import { ReactElement } from "react";

import { DateTimeQuestion } from "../types";

import { Labelled } from "./Label";

import { Input } from "@otta/design";
import { AtsDateFormat } from "@otta/search/schema";

type FieldProps = {
  question: DateTimeQuestion;
  sectionId: string;
};

export function DateTimeField({
  question,
  sectionId,
}: FieldProps): ReactElement {
  const elementId = `${sectionId}_${question.id}`;
  return (
    <Labelled
      htmlFor={elementId}
      required={question.required}
      value={question.label}
    >
      <Field<string, HTMLElement, string>
        initialValue={question.dateTimeAnswer ?? undefined}
        defaultValue={question.dateTimeDefault ?? undefined}
        name={`s_${sectionId}.q_${question.localId}.a_0.stringValue`}
        type={inputType(question.dateTimeFormat)}
        render={({ input, meta }) => (
          <Input {...input} error={meta.touched && meta.error} id={elementId} />
        )}
      />
    </Labelled>
  );
}

function inputType(format: AtsDateFormat): string {
  switch (format) {
    case AtsDateFormat.Date:
      return "date";
    case AtsDateFormat.Time:
      return "time";
    case AtsDateFormat.DateTime:
      return "datetime-local";
  }
}
