import { Helmet } from "react-helmet-async";

import { StartOnboarding } from "./StartOnboarding";

import { useWelcomeToTheJungle } from "@otta/search/hooks/useWelcomeToTheJungle";

const HelmetComponent = () => {
  const companyName = useWelcomeToTheJungle();

  return (
    <Helmet>
      <title>{companyName} | Sign up</title>
      <link
        rel="canonical"
        href={`https://app.${
          companyName === "Welcome to the Jungle"
            ? "welcometothejungle"
            : "otta"
        }.com/sign-up`}
      />
    </Helmet>
  );
};

export const SignupPage: React.FC = () => {
  return (
    <>
      <HelmetComponent />
      <StartOnboarding />
    </>
  );
};
