import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import {
  AtsQuestionAnswerInput,
  AtsQuestionAnswerValueInput,
  JobQuestionDataDocument,
  UpsertJobApplicationAtsQuestionAnswersDocument,
} from "@otta/search/schema";

/*
 * form state is a section -> question -> answer map
 * we don't use arrays for answers due to some dodgy final form behaviour
 * also the IDs are prefixed as final-form breaks if they are numeric strings 🙄
 */
type Answers = Record<`a_${string}`, AtsQuestionAnswerValueInput>;
type Questions = Record<`q_${string}`, Answers>;
type Sections = Record<`s_${string}`, Questions>;
export type FormState = Sections;

/**
 * Hook to take care of formatting records from react-final-form
 * and submit them to the backend in a reasonable format
 */
export const useSubmit = (
  atsQuestionId: string,
  jobId: string
): [(data: FormState) => void, boolean] => {
  const [submit, { loading }] = useMutation(
    UpsertJobApplicationAtsQuestionAnswersDocument,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: JobQuestionDataDocument, variables: { jobId } },
      ],
    }
  );
  return [
    useCallback(
      (data: FormState) => {
        submit({
          variables: {
            jobExternalId: jobId,
            answers: assembleAnswers(data),
            atsQuestionDataExternalId: atsQuestionId,
          },
        });
      },
      [atsQuestionId, jobId, submit]
    ),
    loading,
  ];
};

function assembleAnswers(formData: FormState): AtsQuestionAnswerInput[] {
  return Object.entries(formData).flatMap(([section, questions]) =>
    Object.entries(questions).flatMap(([question, answers]) => ({
      sectionId: section.replace("s_", ""),
      questionId: question.replace("q_", ""),
      value: Object.values(answers),
    }))
  );
}
