import { useEffect } from "react";
import styled from "@xstyled/styled-components";
import { useIsPresent } from "framer-motion";

import { QuestionComponentProps } from "..";

import {
  Spacing,
  SelectField,
  Label as DefaultLabel,
  Text,
} from "@otta/design";
import {
  pxToRem,
  borderRadius,
  modularScale,
  palette,
} from "@otta/design-tokens";
import { Loading } from "@otta/search/components/Loading";
import { pushAnalyticsEvent } from "@otta/analytics";
import { EthnicityEnum, Gender, Pronoun } from "@otta/search/schema";
import { useUserPreferences } from "@otta/search/providers/UserPreferencesProvider/useUserPreferences";
import { Link } from "@otta/search/components/Link";
import { useWelcomeToTheJungle } from "@otta/search/hooks/useWelcomeToTheJungle";

const Container = styled.div`
  text-align: left;
`;

const Disclaimer = styled.div`
  border-radius: ${pxToRem(borderRadius)};
  background-color: white;
  padding: 19;
  border-left: 6px solid ${palette.brand.yellow};
`;

const Label = styled(DefaultLabel)`
  font-size: ${modularScale(-1)};
`;

export const pronounOptions: Array<{ label: string; value: Pronoun }> = [
  { value: Pronoun.SheHer, label: "She/her" },
  { value: Pronoun.HeHim, label: "He/him" },
  { value: Pronoun.TheyThem, label: "They/them" },
  { value: Pronoun.PreferNotToSay, label: "Prefer not to say" },
];

export const genderOptions: Array<{ label: string; value: Gender }> = [
  { value: Gender.Female, label: "Female" },
  { value: Gender.Male, label: "Male" },
  { value: Gender.NonBinary, label: "Non-binary" },
  { value: Gender.Transgender, label: "Transgender" },
  { value: Gender.PreferNotToSay, label: "Prefer not to say" },
];

export const ethnicityOptions: Array<{
  label: string;
  value: EthnicityEnum;
}> = [
  { value: EthnicityEnum.Black, label: "Black or African American" },
  { value: EthnicityEnum.EastAsian, label: "East Asian" },
  { value: EthnicityEnum.IndigenousPeoples, label: "Indigenous Peoples" },
  { value: EthnicityEnum.Hispanic, label: "Hispanic or Latino" },
  { value: EthnicityEnum.MiddleEastern, label: "Middle Eastern" },
  { value: EthnicityEnum.NativeHawaiian, label: "Native Hawaiian" },
  { value: EthnicityEnum.SouthAsian, label: "South Asian" },
  { value: EthnicityEnum.SoutheastAsian, label: "Southeast Asian" },
  { value: EthnicityEnum.White, label: "White" },
  { value: EthnicityEnum.Mixed, label: "Multiracial or Multiethnic" },
  { value: EthnicityEnum.Other, label: "Other" },
  { value: EthnicityEnum.PreferNotToSay, label: "Prefer not to say" },
];

export function Demographic({
  setNextEnabled,
}: QuestionComponentProps): React.ReactElement {
  const isPresent = useIsPresent();
  const companyName = useWelcomeToTheJungle();

  useEffect(() => {
    if (isPresent) {
      setNextEnabled(true);
    }
  }, [setNextEnabled, isPresent]);

  const { preferencesData, updatePreferences } = useUserPreferences();

  if (!preferencesData) {
    return <Loading />;
  }

  const ethnicities = preferencesData.ethnicities ?? [];
  const genders = preferencesData.genders ?? [];
  const pronoun = preferencesData.pronoun;

  const handleChangePronouns = (
    option: { value: Pronoun; label: string } | null
  ) => {
    if (option) {
      updatePreferences({ pronoun: option.value });
    }
  };

  const handleChangeGenders = (
    options: readonly { value: Gender; label: string }[]
  ) => {
    const alreadyHasPreferNotToSay = genders.some(
      gender => gender === Gender.PreferNotToSay
    );
    const hasPreferNotToSay = options.some(
      op => op.value === Gender.PreferNotToSay
    );

    updatePreferences({
      genders:
        hasPreferNotToSay && !alreadyHasPreferNotToSay
          ? [Gender.PreferNotToSay]
          : options
              .filter(op => op.value !== Gender.PreferNotToSay)
              .map(op => op.value),
    });
  };

  const handleChangeEthnicities = (
    options: readonly { value: EthnicityEnum; label: string }[]
  ) => {
    const alreadyHasPreferNotToSay = ethnicities.some(
      e => e.ethnicity === EthnicityEnum.PreferNotToSay
    );
    const hasPreferNotToSay = options.some(
      op => op.value === EthnicityEnum.PreferNotToSay
    );

    updatePreferences({
      ethnicities:
        hasPreferNotToSay && !alreadyHasPreferNotToSay
          ? [{ ethnicity: EthnicityEnum.PreferNotToSay }]
          : options
              .filter(op => op.value !== EthnicityEnum.PreferNotToSay)
              .map(op => ({ ethnicity: op.value })),
    });
  };

  return (
    <Container>
      <Spacing size={2}>
        <Spacing size={-5}>
          <Label htmlFor="pronouns">Pronouns</Label>
          <SelectField<Pronoun>
            inputId="pronouns"
            options={pronounOptions}
            value={pronounOptions.find(op => op.value === pronoun)}
            onChange={handleChangePronouns}
          />
        </Spacing>
        <Spacing size={-5}>
          <Label htmlFor="gender">
            Gender identity (choose all that apply)
          </Label>
          <SelectField<Gender, true>
            inputId="gender"
            isMulti
            options={genderOptions}
            value={genderOptions.filter(op =>
              genders.some(gender => gender === op.value)
            )}
            onChange={handleChangeGenders}
          />
        </Spacing>
        <Spacing size={-5}>
          <Label htmlFor="ethnicity">Ethnicity (choose all that apply)</Label>
          <SelectField<EthnicityEnum, true>
            inputId="ethnicity"
            isMulti
            options={ethnicityOptions}
            value={ethnicityOptions.filter(op =>
              ethnicities.some(e => e.ethnicity === op.value)
            )}
            onChange={handleChangeEthnicities}
          />
        </Spacing>
        <Disclaimer>
          <Spacing>
            <Text size={-1} bold>
              Why are you asking me these questions?
            </Text>
            <Text size={-1}>
              We believe that every candidate should feel included in the
              workplace. {companyName} is dedicated to building a platform that
              helps companies hire in a more inclusive way.
            </Text>
            <Text size={-1}>
              To help us do this, we’d like to know more about you. Sharing this
              data with us is optional.{" "}
              <Link
                to="https://otta.com/privacy-policy"
                onClick={() =>
                  pushAnalyticsEvent({
                    eventName: "Privacy Policy",
                    page: "/initial-preferences/demographic",
                  })
                }
              >
                You can learn more about how we use your personal data in our
                privacy policy.
              </Link>
            </Text>
          </Spacing>
        </Disclaimer>
      </Spacing>
    </Container>
  );
}
