import { Helmet } from "react-helmet-async";
import styled, { css, up } from "@xstyled/styled-components";
import { useEffect } from "react";

import { JobHeader } from "./JobHeader";

import { palette, pxToRem } from "@otta/design-tokens";
import { Card as DefaultCard, Text } from "@otta/design";
import { Job, UserJobPreferences } from "@otta/search/schema";
import { NotFound } from "@otta/search/router";
import { pushEventToDataLayer } from "@otta/analytics";
import { useWelcomeToTheJungle } from "@otta/search/hooks/useWelcomeToTheJungle";

export enum JobCardType {
  jobDeck = "JOB_DECK",
  dashboard = "DASHBOARD",
  public = "PUBLIC",
  application = "APPLICATION",
}

const Card = styled(DefaultCard)`
  padding: 0;
  // So that card looks full even when loading
  min-height: 100vh;
  margin-bottom: 19;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: xl;
`;

export const YellowSpace = styled.div`
  ${up(
    "tablet",
    css`
      background: ${palette.brand.yellow};
      height: ${pxToRem(24)};
    `
  )}
`;

export interface JobCardProps {
  job?: Job.Fragment;
  user?: UserJobPreferences.CurrentUser;
  type: JobCardType;
  publicJob?: boolean;
  toggleKeyboardShortcuts?: (enable: boolean) => void;
}

export function JobCard({
  job,
  user,
  type,
  publicJob = false,
  toggleKeyboardShortcuts,
}: JobCardProps): React.ReactElement {
  const companyName = useWelcomeToTheJungle();
  const isWelcome = companyName === "Welcome to the Jungle";

  useEffect(() => {
    if (job) {
      pushEventToDataLayer({
        event: "user-viewed-job",
        jobId: job?.id,
        jobFunction: job?.function?.value,
        jobSubFunction: job?.subFunction?.value,
        minYearsExperienceRequired: job.minYearsExperienceRequired,
        maxYearsExperience: job.maxYearsExperienceRequired,
        jobPosting: job.originalUrl ? "scraped" : "direct",
      });
    }
  }, [job]);

  if (!job) {
    return (
      <NotFound>
        <Card>
          <ContentContainer>
            <Text align="center">Job not found</Text>
          </ContentContainer>
        </Card>
      </NotFound>
    );
  }

  const jobInvolvesSummary = job.involvesBullets
    ?.map(item => item.value)
    .join(" ")
    ?.slice(0, 50);

  const title = isWelcome
    ? `${job.company.name} ${job.title} | Welcome to the Jungle (formerly Otta)`
    : `${job.title} - ${job.company.name} | ${companyName}`;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="title" content={title} />
        {isWelcome && (
          <meta
            name="description"
            content={`Apply for ${job.company.name} ${
              job.title
            } on Welcome to the Jungle (formerly Otta}. ${
              jobInvolvesSummary ?? ""
            }`}
          />
        )}
        <meta
          property="og:url"
          content={`https://app.${
            companyName === "Welcome to the Jungle"
              ? "welcometothejungle"
              : "otta"
          }.com/jobs/${job.externalId}`}
        />
        <meta property="og:title" content={title} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content={title} />
      </Helmet>
      <ContentContainer data-testid="job-card-v2">
        <JobHeader
          job={job}
          user={user}
          type={type}
          publicJob={publicJob}
          toggleKeyboardShortcuts={toggleKeyboardShortcuts}
        />
      </ContentContainer>
    </>
  );
}
