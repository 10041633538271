import { useEffect, useState } from "react";
import styled from "@xstyled/styled-components";

import { ExampleJobs } from "./ExampleJobs";
import { SalaryHero } from "./SalaryHero";
import { SalaryChart } from "./SalaryChart";
import { SalaryTips } from "./SalaryTips";
import { SalaryFilters, Option } from "./SalaryFilters";

import {
  Middle,
  Spacing,
  Button,
  Text,
  List as DefaultList,
} from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import { pushAnalyticsEvent } from "@otta/analytics";
import {
  CurrentUserDocument,
  JobSubFunction,
  Location,
} from "@otta/search/schema";
import { Link } from "@otta/search/components/Link";
import { LocationOption } from "@otta/search/utils/locations";
import { useWelcomeToTheJungle } from "@otta/search/hooks/useWelcomeToTheJungle";

const PageContainer = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  padding: 3xl md;
`;

const List = styled(DefaultList)`
  width: fit-content;
  margin: auto;
`;

export type SelectOptionFn<T = Option> = (option: T | null) => void;

export function SalaryReport(): React.ReactElement {
  const { data: currentUserData } = useQuery(CurrentUserDocument);
  const companyName = useWelcomeToTheJungle();

  const [selectedLocation, setSelectedLocation] = useState<Location>(
    Location.London
  );

  const [selectedSubFunction, setSelectedSubFunction] =
    useState<JobSubFunction>({
      id: "18",
      value: "Generalist Marketing",
      __typename: "JobSubFunction" as const,
    });

  const [comparisonSubFunction, setComparisonSubFunction] =
    useState<JobSubFunction | null>(null);

  useEffect(() => {
    pushAnalyticsEvent({ eventName: "Viewed Salary Report" });
  }, []);

  const handleSelectLocation: SelectOptionFn<LocationOption> = option => {
    if (option) {
      setSelectedLocation(option.value);
    }
  };

  const handleSelectSubFunction: SelectOptionFn = option => {
    if (option) {
      setSelectedSubFunction({
        id: option.value,
        value: option.label,
        __typename: "JobSubFunction",
      });
    }
  };

  const handleSelectComparisonSubFunction: SelectOptionFn = option => {
    if (option) {
      setComparisonSubFunction({
        id: option.value,
        value: option.label,
        __typename: "JobSubFunction",
      });
    } else {
      setComparisonSubFunction(null);
    }
  };

  const loggedIn = !!currentUserData?.currentUser;

  return (
    <PageContainer>
      <Spacing size={8}>
        <Spacing>
          <Middle maxWidth={590}>
            <SalaryHero />
          </Middle>
          <SalaryFilters
            selectedSubFunction={selectedSubFunction}
            comparisonSubFunction={comparisonSubFunction}
            selectedLocation={selectedLocation}
            onSelectSubFunction={handleSelectSubFunction}
            onSelectComparisonSubFunction={handleSelectComparisonSubFunction}
            onSelectLocation={handleSelectLocation}
          />
          <SalaryChart
            location={selectedLocation}
            subFunction={selectedSubFunction}
            comparisonSubFunction={comparisonSubFunction}
          />
        </Spacing>
        <Middle maxWidth={900}>
          <ExampleJobs
            subFunction={selectedSubFunction}
            location={selectedLocation}
          />
        </Middle>
        <Middle maxWidth={500}>
          <Spacing>
            <Text as="h4" size={2} bold>
              {loggedIn ? "How we verify data" : "Thinking about a new job?"}
            </Text>
            <Text>
              {loggedIn
                ? "We use data from 4 places to ensure we provide a fair reflection of salaries at top startups:"
                : `At ${companyName} we're fanatical about helping you find a job you love. We only work with the fastest-moving, most innovative companies. And unlike LinkedIn, there's no endless scrolling`}
            </Text>
            {loggedIn ? (
              <List type="positive">
                <li>Public salary bands on job descriptions</li>
                <li>Private input from companies</li>
                <li>Anonymous submissions from employees</li>
                <li>Salary expectations from candidates on {companyName}</li>
              </List>
            ) : (
              <Link to="/sign-up" underline={false}>
                <Button level="primary" type="button">
                  Sign up
                </Button>
              </Link>
            )}
          </Spacing>
        </Middle>
        <Middle maxWidth={590}>
          <Text as="h4" size={2} bold>
            How to use this data
          </Text>
          <SalaryTips />
        </Middle>
      </Spacing>
    </PageContainer>
  );
}
