import { useNavigate } from "react-router-dom";

import { BottomPopup } from "@otta/search/components/BottomPopup";
import { useWelcomeToTheJungle } from "@otta/search/hooks/useWelcomeToTheJungle";

export const SetYourPreferencesPopup = ({
  onClose,
}: {
  onClose: () => void;
}): React.ReactElement => {
  const navigate = useNavigate();

  const companyName = useWelcomeToTheJungle();

  const heading = `${companyName} - the better way to find a job in tech`;
  const subHeading =
    "Only relevant roles. At the most exciting startups. Discover your top recommendations now.";

  const handleClick = () => {
    navigate("/sign-up");
  };

  return (
    <BottomPopup
      handleClickCTA={handleClick}
      onClose={onClose}
      heading={heading}
      headingSize={0}
      subHeading={subHeading}
      analyticsId="popup-set-preferences-button"
    >
      Set your Job Preferences
    </BottomPopup>
  );
};
