import { CSSProperties } from "react";

export interface LogoProps {
  className?: string;
  style?: CSSProperties;
  color?: string;
}
/**
 * ```ts
 *
 * import { Logo } from '@otta/design'
 *
 * ```
 */

export function Logo({
  className,
  style,
  color = "#000",
}: LogoProps): React.ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 240"
      className={className}
      style={style}
      fill={color}
    >
      <path d="M544.42 3.109a166.118 166.118 0 00-45.557 6.743q-23.681 6.744-46.756 15.736V75.34q35.36-13.487 53.649-18.282 18.276-4.79 32.669-4.796 35.966 0 41.96 25.776-46.462 8.698-75.678 18.582-29.223 9.891-47.055 28.323-17.837 18.433-17.834 48.705 0 19.184 8.692 33.418a58.445 58.445 0 0024.127 22.03q15.432 7.788 34.618 7.791a89.591 89.591 0 0045.107-11.689 71.286 71.286 0 0030.42-33.867v41.36h54.549V93.325q0-44.958-23.377-67.586-23.379-22.627-69.535-22.63M370.584 4.908q-26.075 7.496-54.849 14.686v46.456q-6 1.504-17.383 3.896v46.456l7.193-1.498 9.89-1.798v56.047q0 67.738 65.64 67.735 30.267 0 52.15-14.985l-13.787-45.857a71.659 71.659 0 01-16.035 7.942 46.574 46.574 0 01-14.237 2.248q-18.882 0-18.882-23.078v-62.64q30.57-8.093 64.739-19.182V31.582q-34.774 11.688-64.44 20.08V4.908zm-106.1 184.625q-19.488 0-19.482-25.176v-39.862q23.379-2.992 36.266-5.395V73.243q-11.395 2.403-36.266 5.694V33.98q-20.084 3.597-51.85 7.194V85.23q-4.8.606-18.883 1.499v44.658q6.295-.294 18.883-1.5v39.863q0 67.143 64.139 67.136 30.57 0 51.551-15.285l-14.686-42.56a81.39 81.39 0 01-15.735 8.092 41.203 41.203 0 01-13.937 2.398M86.153 81.035q-23.681 0-42.56 10.04a76.767 76.767 0 000 135.772q18.882 10.038 42.56 10.04 23.671 0 42.56-10.04a76.767 76.767 0 000-135.771q-18.883-10.038-42.56-10.041m434.589 108.797q-11.093 0-17.834-6.294-6.742-6.294-6.743-16.484 0-13.782 8.992-22.18 8.99-8.385 25.925-13.637Q548.01 126 581.584 119.1a85.132 85.132 0 01-10.64 35.217q-9.148 16.34-22.629 25.925-13.487 9.597-27.573 9.59m-434.59 3.298q-14.99 0-24.875-9.74-9.892-9.736-9.891-24.428 0-14.68 9.89-24.426 9.891-9.735 24.877-9.741 14.983 0 24.876 9.74 9.891 9.744 9.89 24.427 0 14.69-9.89 24.427-9.89 9.744-24.876 9.741" />
    </svg>
  );
}
