import styled, { up, css } from "@xstyled/styled-components";
import {
  format,
  differenceInYears,
  addYears,
  differenceInMonths,
  parseISO,
} from "date-fns";

import {
  Paragraph,
  Spacing,
  Text,
  Logo as OttaLogo,
  WttjLogo,
} from "@otta/design";
import { pxToRem, borderRadius, palette } from "@otta/design-tokens";
import { Link } from "@otta/search/components/Link";
import { Tag } from "@otta/search/components/Tag";
import { useHasNewLogo } from "@otta/search/components/Logo";

const Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const OrgName = styled(Text)<{
  underline?: boolean;
}>`
  vertical-align: middle;
  display: inline;
  margin: 0;
  ${({ underline }) => underline && "text-decoration: underline;"}
`;

export const Title = styled(Text)`
  display: block;
  margin: xs 0;
`;

export const Dates = styled(Text)<{ noTitle?: boolean }>`
  ${({ noTitle }) => noTitle && "padding-top: 6;"}
  white-space: nowrap;
  flex-grow: 0;
`;

const TechnologiesTagWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  padding-top: xs;
`;

const OttaLogoWrapper = styled.div`
  display: inline-block;
  width: ${pxToRem(24)};
  margin-left: 6;
`;

const WttjLogoWrapper = styled.div`
  display: inline-block;
  margin-left: ${pxToRem(6)};
`;

const StyledWttjLogo = styled(WttjLogo)`
  width: ${pxToRem(64)};
  margin-top: ${pxToRem(4)};
`;

const OttaCompanyWrapper = styled.div`
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
  margin-left: md;
  background: ${palette.beige.shade200};
  padding: xxs sm;
  border-radius: ${pxToRem(borderRadius)};
`;

const OttaCompanyText = styled(Text)`
  display: inline-block;
  color: black;
`;

const Description = styled(Paragraph)`
  display: block;
  margin: xs 0;
  white-space: pre-wrap;
  max-width: none;
  flex-grow: 1;
  flex-basis: ${pxToRem(200)};
`;

const DescriptionWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column-reverse;

  ${up(
    "desktop",
    css`
      flex-direction: row;
      align-items: flex-end;
      flex-wrap: wrap-reverse;
    `
  )}
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: ${pxToRem(400)};

  ${up(
    "desktop",
    css`
      width: ${pxToRem(150)};
      min-width: ${pxToRem(150)};
      max-width: ${pxToRem(200)};
      flex-basis: ${pxToRem(100)};
      flex-grow: 1;
    `
  )}
`;

const Image = styled.img`
  width: 100%;
  object-fit: contain;
  margin: sm 0;
  border-radius: 4;

  ${up(
    "desktop",
    css`
      margin-left: lg;
    `
  )}
`;

const getPreference = (jobTechnologiesUsed: { id: string }[], id: string) =>
  jobTechnologiesUsed.map(({ id: techId }) => techId).includes(id)
    ? true
    : undefined;

const plural = (n: number) => (n === 1 ? "" : "s");

const yearsAndMonths = (
  startDate: string | null,
  endDate: string | null
): string => {
  const start = startDate ? parseISO(startDate) : new Date();
  const end = endDate ? parseISO(endDate) : new Date();

  const years = differenceInYears(end, start);
  const startMonths = addYears(start, years);
  const months = differenceInMonths(end, startMonths);

  if (years > 0 && months > 0) {
    return `${years} year${plural(years)} & ${months} month${plural(months)}`;
  } else if (years > 0) {
    return `${years} year${plural(years)}`;
  } else if (months > 0) {
    return `${months} month${plural(months)}`;
  } else if (months === 0 && start <= end) {
    return `1 month`;
  }
  return "";
};

export const DatesToFrom = ({
  startDate,
  endDate,
  dateFormat,
}: {
  startDate: string;
  endDate: string | null;
  dateFormat: string;
}): React.ReactElement => {
  const startDateFormatted = format(parseISO(startDate), dateFormat);
  if (startDate === endDate) {
    return <>{startDateFormatted}</>;
  } else if (endDate) {
    return (
      <>
        {startDateFormatted} - {format(parseISO(endDate), dateFormat)}
      </>
    );
  }
  return <>{startDateFormatted} - Present</>;
};

export const OttaCompany = (): React.ReactElement => {
  const hasNewLogo = useHasNewLogo();

  return (
    <OttaCompanyWrapper>
      <OttaCompanyText as="span" size={-1} bold align="left">
        Company on
      </OttaCompanyText>
      {hasNewLogo ? (
        <WttjLogoWrapper>
          <StyledWttjLogo />
        </WttjLogoWrapper>
      ) : (
        <OttaLogoWrapper>
          <OttaLogo />
        </OttaLogoWrapper>
      )}
    </OttaCompanyWrapper>
  );
};

const orderTechnologies =
  (preferences: { id: string }[]) => (a: { id: string }, b: { id: string }) => {
    const preferenceA = getPreference(preferences, a.id);
    const preferenceB = getPreference(preferences, b.id);
    if (preferenceA && !preferenceB) {
      return -1;
    } else if (preferenceB && !preferenceA) {
      return 1;
    } else {
      return parseInt(b.id, 10) - parseInt(a.id, 10);
    }
  };

export function Technologies({
  technologiesUsed,
  experienceTechnologiesUsed,
  className,
}: {
  technologiesUsed: { id: string; value: string }[];
  experienceTechnologiesUsed: { id: string; value: string }[];
  className?: string;
}): React.ReactElement {
  const technologiesSet = new Set(
    experienceTechnologiesUsed.map(({ id }) => id)
  );
  const orderedTechnologiesUsed = [...technologiesUsed].sort(
    orderTechnologies(experienceTechnologiesUsed)
  );
  return (
    <TechnologiesTagWrapper className={className}>
      {orderedTechnologiesUsed.map(({ id, value }) => (
        <Tag
          key={id}
          backgroundColor={
            technologiesSet.has(id)
              ? palette.extended.yellow.shade100
              : palette.grayscale.shade50
          }
        >
          {value}
        </Tag>
      ))}
    </TechnologiesTagWrapper>
  );
}

interface IExperienceItemProps {
  title: string | null;
  companyUrl?: string | null;
  orgName: string;
  startDate?: string | null;
  endDate?: string | null;
  showMonth: boolean;
  ottaCompany?: boolean;
  description?: string;
  showDuration?: boolean;
  technologiesUsed?: { id: string; value: string }[];
  experienceTechnologiesUsed?: { id: string; value: string }[];
  image?: string;
}

export function ExperienceItem({
  title,
  orgName,
  ottaCompany = false,
  companyUrl,
  startDate = null,
  endDate = null,
  technologiesUsed = [],
  showDuration,
  showMonth,
  experienceTechnologiesUsed = [],
  description,
  image,
}: IExperienceItemProps): React.ReactElement {
  const dateFormat = showMonth ? "MMM yy" : "yyyy";
  return (
    <Spacing size={-4}>
      <Content>
        <div style={{ maxWidth: pxToRem(370) }}>
          {companyUrl && !companyUrl.includes("?keywords") ? (
            <Link to={companyUrl} targetBlank>
              <OrgName
                align="left"
                bold
                onClick={e => e.stopPropagation()}
                underline
              >
                {orgName}
              </OrgName>
            </Link>
          ) : (
            <OrgName data-cs-mask align="left" bold>
              {orgName}
            </OrgName>
          )}
          {ottaCompany && <OttaCompany />}
          {title && (
            <Title data-cs-mask bold align="left" as="span">
              {title}
            </Title>
          )}
          <Dates align="left" size={-1} noTitle={!title}>
            {showDuration && yearsAndMonths(startDate, endDate)}
          </Dates>
        </div>
        {startDate && (
          <Dates align="left" size={-1} noTitle={!title}>
            <DatesToFrom
              startDate={startDate}
              endDate={endDate}
              dateFormat={dateFormat}
            />
          </Dates>
        )}
      </Content>
      {technologiesUsed.length > 0 && (
        <Technologies
          technologiesUsed={technologiesUsed}
          experienceTechnologiesUsed={experienceTechnologiesUsed}
        />
      )}
      {description && (
        <DescriptionWrapper>
          <Description data-cs-mask align="left" size={-1}>
            {description}
          </Description>
          {image && (
            <ImageWrapper>
              <Image src={image} alt="upload" data-testid="experience-image" />
            </ImageWrapper>
          )}
        </DescriptionWrapper>
      )}
    </Spacing>
  );
}
