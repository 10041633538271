import styled, { css, up } from "@xstyled/styled-components";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useParams, useSearchParams } from "react-router-dom";
import store from "store";
import { useInView } from "react-intersection-observer";
import { motion, AnimatePresence } from "framer-motion";

import { JobCard, JobCardType, YellowSpace } from "../JobCard";
import { JobNotFound } from "../JobNotFound";

import { jobPostingSchema } from "./jobPostingSchema";
import { PublicJobActionPanel } from "./components/PublicJobActionPanel";

import {
  palette,
  modularScale,
  borderRadius,
  pxToRem,
} from "@otta/design-tokens";
import { Button, Heading, Middle, Spacing, Text } from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import { pushAnalyticsEvent } from "@otta/analytics";
import { jobProperties } from "@otta/search/utils/analytics/jobProperties";
import {
  CurrentUserDocument,
  JobWorkflowStatus,
  PublicJobDocument,
} from "@otta/search/schema";
import { PageWrapper } from "@otta/search/components/PageWrapper";
import { useUserLocation } from "@otta/search/hooks/useUserLocation";
import { countryToCurrency } from "@otta/search/utils/currencies";
import { NotFound } from "@otta/search/router";
import { useWelcomeToTheJungle } from "@otta/search/hooks/useWelcomeToTheJungle";

const VIEWED_JOBS_STORE_KEY = "_otta_viewed_job_ids";
const MAX_VIEWED_JOBS = 1;

const JobCardWrapper = styled.div<{ $hasViewedTooManyJobs?: boolean }>`
  width: 100%;
  padding: 0;
  margin: 0;
  position: relative;
  ${({ $hasViewedTooManyJobs }) =>
    $hasViewedTooManyJobs &&
    css`
      overflow-y: hidden;
      max-height: 200vh;

      ${up(
        "tablet",
        css`
          max-height: calc(100vh + ${pxToRem(128)});
        `
      )}}
    `}
`;

const SignUpContainer = styled(motion.div)`
  position: fixed;
  z-index: 1;
  bottom: 0;
  max-width: 1024px;
  width: 100%;
  left: 50%;
  background: ${palette.brand.yellow};
  border-top-left-radius: ${pxToRem(borderRadius)};
  border-top-right-radius: ${pxToRem(borderRadius)};
  padding: 40;
  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 50vh;
    background: ${palette.brand.yellow};
  }
`;

const GreySpace = styled.div`
  background: ${palette.beige.shade200};
  height: ${modularScale(2)};
  ${up(
    "tablet",
    css`
      height: 0;
    `
  )}
`;

export function PublicJob(): React.ReactElement {
  const { jobId } = useParams<"jobId">();
  const [searchParams] = useSearchParams();

  const utmSource = searchParams.get("utm_source");

  const [hasViewedTooManyJobs, setHasViewedTooManyJobs] = useState(false);

  const { data, loading } = useQuery(CurrentUserDocument, { ssr: true });

  useEffect(() => {
    if (loading || data?.currentUser) {
      return;
    }

    const jobIds = store.get(VIEWED_JOBS_STORE_KEY, []);

    if (
      !Array.isArray(jobIds) ||
      jobIds.some(id => typeof id !== "string") ||
      jobIds.length > MAX_VIEWED_JOBS
    ) {
      return setHasViewedTooManyJobs(true);
    }

    const newJobIds = Array.from(
      new Set<string>(jobIds).add(jobId as string).values()
    );

    store.set(VIEWED_JOBS_STORE_KEY, newJobIds);

    if (newJobIds.length > MAX_VIEWED_JOBS) {
      return setHasViewedTooManyJobs(true);
    }
  }, [data?.currentUser, jobId, loading]);

  return (
    <PublicJobComponent
      jobId={jobId as string}
      utmSource={utmSource}
      hasViewedTooManyJobs={hasViewedTooManyJobs}
    />
  );
}

function PublicJobComponent({
  jobId,
  hasViewedTooManyJobs,
  utmSource,
}: {
  jobId: string;
  hasViewedTooManyJobs?: boolean;
  utmSource: string | null;
}): React.ReactElement | null {
  const { country: userLocation } = useUserLocation();
  const { data, loading } = useQuery(PublicJobDocument, {
    ssr: true,
    variables: {
      externalId: jobId,
      currency: countryToCurrency(userLocation),
      prefixImage: false,
    },
  });

  const [jobWrapperRef, inView] = useInView({
    triggerOnce: true,
    skip: !hasViewedTooManyJobs,
  });

  const job = data?.publicJob;

  const companyName = useWelcomeToTheJungle();

  const showSignUpPrompt = !!job && hasViewedTooManyJobs && inView;
  const applyViaOtta = job?.acceptsInternalApplications;
  const postedDirectly = !job?.originalUrl;

  useEffect(() => {
    if (job) {
      pushAnalyticsEvent({
        eventName: "Viewed Public Job",
        utmSource,
        applyViaOtta: job.acceptsInternalApplications,
        postedDirectly: !job.originalUrl,
        ...jobProperties(job),
        jobId,
      });
    }
  }, [job, jobId, utmSource, applyViaOtta, postedDirectly]);

  useEffect(() => {
    if (showSignUpPrompt) {
      pushAnalyticsEvent({
        eventName: "Candidate Shown",
        name: "Public Sign Up Gate",
      });
    }
  }, [showSignUpPrompt]);

  if (loading) {
    return <Loading />;
  }

  if (!job) {
    return (
      <NotFound>
        <JobNotFound />
      </NotFound>
    );
  }

  return (
    <>
      <Helmet>
        <meta property="og:type" content="article" />
        <meta property="og:publisher" content="Otta" />
        {job.workflowStatus === JobWorkflowStatus.Inactive && (
          <meta name="robots" content="noindex" />
        )}
        <link
          rel="canonical"
          href={`https://app.${
            companyName === "Welcome to the Jungle"
              ? "welcometothejungle"
              : "otta"
          }.com/jobs/${job.externalId}`}
        />
        <script type="application/ld+json">{jobPostingSchema({ job })}</script>
      </Helmet>
      <PageWrapper>
        <JobCardWrapper $hasViewedTooManyJobs={hasViewedTooManyJobs}>
          <GreySpace />
          <YellowSpace />
          <JobCard job={job} type={JobCardType.public} publicJob />
          <div
            ref={jobWrapperRef}
            style={{ position: "absolute", bottom: 0 }}
          />
        </JobCardWrapper>
        <AnimatePresence initial={false}>
          {showSignUpPrompt ? (
            <SignUpContainer
              key="signup"
              style={{ x: "-50%" }}
              initial={{ y: "100%" }}
              animate={{ y: 0 }}
              exit={{ y: "100%" }}
              transition={{
                type: "spring",
                stiffness: 91,
                damping: 12,
              }}
            >
              <Middle maxWidth={550}>
                <Spacing size={1}>
                  <Heading size={1} bold>
                    Welcome to the better way to find a job in tech
                  </Heading>

                  <Text>
                    Sign up to apply for this role and match with 50,000+ jobs
                    from the world&#39;s best tech companies.
                  </Text>

                  <Button
                    level="secondary"
                    as={Link}
                    data-analytics-id="public-sign-up-gate-sign-up-button"
                    to={{
                      pathname: "/sign-up",
                      search: queryString.stringify({
                        utm_source: utmSource,
                        redirect: `/jobs/${job.externalId}`,
                      }),
                    }}
                  >
                    Sign up to apply
                  </Button>
                  <Spacing size={-5}>
                    <Text size={-1}>Already have an account?</Text>
                    <Text
                      as={Link}
                      bold
                      to={{
                        pathname: "/login",
                        search: queryString.stringify({
                          utm_source: utmSource,
                          redirect: `/jobs/${job.externalId}`,
                        }),
                      }}
                    >
                      Sign in
                    </Text>
                  </Spacing>
                </Spacing>
              </Middle>
            </SignUpContainer>
          ) : (
            <PublicJobActionPanel
              job={job}
              utmSource={utmSource ?? undefined}
            />
          )}
        </AnimatePresence>
      </PageWrapper>
    </>
  );
}
