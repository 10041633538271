import { useMutation } from "@apollo/client";
import { MouseEvent, useState } from "react";
import { Field, Form as FinalForm } from "react-final-form";
import { useLocation } from "react-router-dom";

import {
  Button,
  Text,
  Spacing,
  InputField,
  Label,
  Middle,
  ErrorText,
} from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import {
  composeValidators,
  required,
  validEmail,
} from "@otta/search/utils/validators";
import { pushAnalyticsEvent } from "@otta/analytics";
import {
  JobFunctionsDocument,
  CreateCompanyEmployeeSurveyDocument,
  EmployeeSurveyCompany,
} from "@otta/search/schema";
import { Select } from "@otta/search/components/Input/Select";
import { Link } from "@otta/search/components/Link";
import { handleMutationError } from "@otta/search/utils/error";

const mapJobFunctionsToOptions = (
  jobFunctions: { id: string; value: string }[]
) => {
  return jobFunctions.map(jobFunction => {
    return { value: jobFunction.id, label: jobFunction.value };
  });
};

interface IEmployeeSurveySignUpForm {
  company: EmployeeSurveyCompany.Company;
}

export function EmployeeSurveySignUpForm({
  company,
}: IEmployeeSurveySignUpForm): React.ReactElement | null {
  const location = useLocation();

  const [isComplete, setIsComplete] = useState(false);
  const { data: jobFunctionsData } = useQuery(JobFunctionsDocument);
  const jobFunctions = jobFunctionsData?.jobFunctions;

  const onCompleted = () => setIsComplete(true);

  const [mutate] = useMutation(CreateCompanyEmployeeSurveyDocument, {
    ignoreResults: true,
    onError: handleMutationError,
    onCompleted,
  });

  const onSubmit = (values: {
    email: string;
    jobFunction: { value: string };
  }) => {
    const {
      email,
      jobFunction: { value },
    } = values;

    mutate({
      variables: { companyId: company.id, email, jobFunctionId: value },
    });
  };

  const pushClickEvent = (event: MouseEvent<HTMLButtonElement>) => {
    pushAnalyticsEvent({
      eventName: "Employee Clicked",
      pathname: location.pathname,
      name: "endorsements-verify-email",
      companyId: company.id,
    });
    event.stopPropagation();
  };

  if (!jobFunctions) {
    return null;
  }

  function validateEmailDomain<T = unknown>(value: T): string | undefined {
    return typeof value === "string" &&
      value.split("@").pop() === company.domainName
      ? undefined
      : `Must be an @${company.domainName} email`;
  }

  return (
    <Middle maxWidth={300}>
      {!isComplete ? (
        <Spacing>
          <Text as="h2" bold align="center">
            We need your email to verify you work at {company.name}
          </Text>
          <FinalForm
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Spacing>
                  <Field
                    name="email"
                    validate={composeValidators(
                      required,
                      validEmail,
                      validateEmailDomain
                    )}
                  >
                    {({ input, meta }) => (
                      <InputField
                        {...input}
                        label="Work email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        error={meta.touched && meta.error}
                      />
                    )}
                  </Field>
                  <Field name="jobFunction" validate={required}>
                    {({ input, meta }) => (
                      <div style={{ textAlign: "left" }}>
                        <Spacing size={-4}>
                          <Label id="team-select-label">Team</Label>
                          <Select
                            aria-labelledby="team-select-label"
                            {...input}
                            options={mapJobFunctionsToOptions(jobFunctions)}
                          />
                        </Spacing>
                        {meta.error && meta.touched && (
                          <ErrorText>{meta.error}</ErrorText>
                        )}
                      </div>
                    )}
                  </Field>
                  <Text size={-1}>
                    By leaving an endorsement you agree to our{" "}
                    <Link to="https://otta.com/privacy-policy">
                      Privacy Policy
                    </Link>{" "}
                    and{" "}
                    <Link to="https://otta.com/terms-and-conditions/candidates">
                      Terms &amp; Conditions
                    </Link>
                    .
                  </Text>
                  <Middle>
                    <Button
                      style={{ width: "100%" }}
                      level="primary"
                      type="submit"
                      onClick={pushClickEvent}
                    >
                      Next
                    </Button>
                  </Middle>
                </Spacing>
              </form>
            )}
          />
        </Spacing>
      ) : (
        <Spacing>
          <Text bold as="h2">
            Thank you
          </Text>
          <Text>
            We just need to verify you work at {company.name}. You should get an
            email shortly with a link to continue
          </Text>
        </Spacing>
      )}
    </Middle>
  );
}
