import { Spacing, Text, List } from "@otta/design";
import { useWelcomeToTheJungle } from "@otta/search/hooks/useWelcomeToTheJungle";
import { ApplyModalJob } from "@otta/search/schema";

interface InternalApplicationSummaryProps {
  company: ApplyModalJob.Company;
}

export const InternalApplicationSummary = ({
  company: { name },
}: InternalApplicationSummaryProps): React.ReactElement => {
  const companyName = useWelcomeToTheJungle();

  return (
    <Spacing>
      <Text bold align="center">
        {name} is accepting applications via {companyName} because we help
        candidates write great applications
      </Text>
      <List type="positive">
        <li>Companies prefer receiving applications through {companyName}</li>
        <li>We&apos;ll guide you to write a great application and stand out</li>
      </List>
    </Spacing>
  );
};
